import * as React from "react"
import Template from "../components/template"

const MenuPage = () => {
  return (
    <Template>
      <main className="max-w-screen-lg mx-auto">Ecco il menu</main>
    </Template>
  )
}

export default MenuPage
